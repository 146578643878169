<template>
  <div class="users">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      :value="getUsers"
      :loading="isLoading"
      :rowHover="true"
      :lazy="true"
      filterDisplay="row"
      :stateKey="tableStateStore.filterName()"
      :filters.sync="tableStateStore.filters"
      :rows.sync="tableStateStore.pagination.pageSize"
      :sortField="tableStateStore.sorting.sortField"
      :sortOrder="tableStateStore.sorting.sortOrder"
      :first="tableStateStore.skip()"
      stateStorage="local"
      :totalRecords="getUserCount"
      :paginator="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h1 class="page-title">{{ $t('users') }}</h1>
          </div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
            <div class="button-right">
              <router-link class="btn btn-success mb-2 right" :to="{ name: 'UserCreatePage' }">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('user_create') }}
              </router-link>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        field="number"
        header="#"
        sortable
        :headerStyle="{ width: '60px' }"
        :bodyStyle="'text-align: center;width: 60px'"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data && slotProps.data.number !== null"
            :to="{
              name: 'UserEditPage',
              params: { userNumber: slotProps.data.number },
            }"
          >
            {{ slotProps.data.number.toString().padStart(4, '0') }}
          </router-link>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="lastname" :header="$t('lastname')" sortable filterMatchMode="contains">
        <template #body="slotProps">
          <span v-if="slotProps.data && slotProps.data.lastname !== null">{{
            slotProps.data.lastname
          }}</span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="firstname" :header="$t('firstname')" sortable filterMatchMode="contains">
        <template #body="slotProps">
          <span v-if="slotProps.data && slotProps.data.firstname !== null">{{
            slotProps.data.firstname
          }}</span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="email" :header="$t('email')" sortable filterMatchMode="contains">
        <template #body="slotProps">
          <a
            v-if="slotProps.data && slotProps.data.email !== null"
            :href="'mailto:' + slotProps.data.email"
            >{{ slotProps.data.email }}</a
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="clients" :header="$t('clients')" :sortable="true" :showFilterMenu="false">
        <template #body="slotProps">
          <ul>
            <li v-for="(client, index) in slotProps.data.clients" :key="index">
              {{ client.name }}
            </li>
          </ul>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableStateStore.customFilters.clients"
            :options="clients"
            @input="onClientsFilter"
            optionLabel="label"
            :placeholder="$t('clients')"
            display="chip"
            :style="{ 'max-width': '180px' }"
          />
        </template>
      </Column>

      <Column
        field="client.name"
        :header="$t('client') + ' (veraltet)'"
        :sortable="true"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <span
            v-if="slotProps.data && slotProps.data.client && slotProps.data.client.name !== null"
            >{{ slotProps.data.client.name }}</span
          >
        </template>

        <template #filter>
          <MultiSelect
            :value="tableStateStore.customFilters.client"
            :options="clients"
            @input="onClientFilter"
            optionLabel="label"
            :placeholder="$t('clients')"
            display="chip"
            :style="{ 'max-width': '180px' }"
          />
        </template>
      </Column>

      <Column
        field="role"
        :header="$t('role')"
        sortable
        filterMatchMode="contains"
        :headerStyle="{ width: '110px' }"
        :bodyStyle="'text-align: center;width: 110px'"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <b-badge
            v-if="slotProps.data && slotProps.data.role !== null"
            :variant="userRoleColor(slotProps.data.role)"
          >
            {{ $t(slotProps.data.role) }}
          </b-badge>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableStateStore.customFilters.userRoles"
            :options="userRoles"
            @input="onUserRoleFilter"
            optionLabel="label"
            :placeholder="$t('role')"
            display="chip"
            :style="{ 'max-width': '180px' }"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import { getUserRoleColor } from '@/helpers/colors';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

export default {
  name: 'UsersPage',
  components: {
    Breadcrumbs,
    DataTable,
    InputText,
    Column,
    MultiSelect,
  },
  data() {
    return {
      userTableStateConfig: {
        name: 'users',
        filters: {
          number: { value: null },
          firstname: { value: null },
          lastname: { value: null },
          email: { value: null },
        },
        customFilters: {
          client: [],
          clients: [],
          userRoles: [],
        },
        sorting: { sortField: 'number', sortOrder: 1 },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getUserCount',
      'isLoading',
      'getClients',
      'getTableState',
      'getUserRoles',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('users') }];
    },
    tableStateStore() {
      return this.getTableState(this.userTableStateConfig.name);
    },
    clients() {
      const clients = this.getClients.map((client) => {
        return { value: client.id.toString(), label: client.name };
      });
      return clients;
    },
    userRoles() {
      const userRoles = [];
      this.getUserRoles.map((role) => {
        userRoles.push({ value: role.name, label: this.$t(role.name) });
      });

      return userRoles;
    },
  },
  methods: {
    ...mapActions(['fetchUsersPaginated', 'fetchClients', 'initTableState', 'fetchUserRoles']),
    userRoleColor($role) {
      return getUserRoleColor($role);
    },

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchUsersPaginated({
        pagination: {
          skip: this.tableStateStore.skip(),
          pageSize: this.tableStateStore.pagination.pageSize,
        },
        sorting: this.tableStateStore.sorting,
        filters: { ...this.tableStateStore.filters, ...this.tableStateStore.customFilters },
      });
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableStateStore.pagination = { page: event.page, pageSize: event.rows };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableStateStore.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },

    /**
     * Remove all filters
     * @param {*} event
     */
    async onRemoveFilters(event) {
      this.tableStateStore.reset();
      await this.loadTableData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      this.tableStateStore.restore(event);
    },

    /**
     * Filter for the single assigned client (deprecated)
     * @param value
     */
    async onClientFilter(value) {
      const filteredClients = { client: this.clients.filter((client) => value.includes(client)) };
      this.tableStateStore.setCustomFilters(filteredClients);
      await this.loadTableData();
    },

    /**
     * Filter for the multiple assigned clients
     * @param value
     */
    async onClientsFilter(value) {
      const filteredClients = { clients: this.clients.filter((client) => value.includes(client)) };
      this.tableStateStore.setCustomFilters(filteredClients);
      await this.loadTableData();
    },

    /**
     * Filter by user roles
     * @param value
     */
    async onUserRoleFilter(value) {
      const filteredRoles = {
        userRoles: this.userRoles.filter((userRole) => value.includes(userRole)),
      };
      this.tableStateStore.setCustomFilters(filteredRoles);
      await this.loadTableData();
    },
  },
  async created() {
    await this.initTableState(this.userTableStateConfig);
  },
  async mounted() {
    await this.fetchUserRoles();
    await this.fetchClients();
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-column-filter-clear-button {
  display: none;
  width: 0;
}
::v-deep .p-column-filter-menu-button {
  display: none;
  width: 0;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 75vh;
}
</style>
